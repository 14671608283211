"use client";
import React, { useState, useRef } from 'react';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';

const TestimonialSlider = ({ 
  testimonials, 
  title = "What Our Clients Say",
  quoteIconSize = 200,
  imageSize = 100,
  showNavigationButtons = true,
  showPaginationDots = true,
  className = "",
  organizationName = "Infyways Solutions",
  organizationUrl = "https://www.infyways.com"
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const constraintsRef = useRef(null);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.x < -50) {
      nextTestimonial();
    } else if (info.offset.x > 50) {
      prevTestimonial();
    }
  };

  const jsonLd = testimonials.map((testimonial, index) => ({
    "@context": "https://schema.org",
    "@type": "Review",
    "itemReviewed": {
      "@type": "Organization",
      "name": organizationName,
      "url": organizationUrl
    },
    "reviewBody": testimonial.text.replace(/\*\*/g, ''),
    "author": {
      "@type": "Person",
      "name": testimonial.name
    },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5",
      "bestRating": "5"
    }
  }));


  return (
    <>
    
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
   
      <div className={`relative py-16 overflow-hidden ${className}`}>
        <div className="max-w-5xl mx-auto px-4 sm:min-h-[500px]">
          <h2 className="text-3xl sm:text-6xl font-bold text-center mb-12 relative">
            <div className="absolute inset-0 top-0 right-0 opacity-20 mb-12 text-gray-300">
              <Quote size={quoteIconSize}/>
            </div>
            {title}
          </h2>
        
          <div className="relative" ref={constraintsRef}>
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -300 }}
                transition={{ duration: 0.3 }}
                className="p-8 md:p-12 flex flex-col md:flex-row"
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={0.1}
                onDragEnd={handleDragEnd}
              >
                <div className="md:w-1/5 mb-6 md:mb-0">
                  <Image
                    src={testimonials[currentIndex].image}
                    alt={testimonials[currentIndex].name}
                    width={imageSize}
                    height={imageSize}
                    className="rounded-full mx-auto"
                  />
                </div>
                <div className="md:w-4/5 md:pl-8">
                  {/* Use react-markdown to render the testimonial text */}
                  <ReactMarkdown className="mb-4 text-md mt-0">
                    {testimonials[currentIndex].text}
                  </ReactMarkdown>
                  <p className="font-bold">{testimonials[currentIndex].name}</p>
                  <p className="text-sm text-gray-900">{testimonials[currentIndex].position}</p>
                </div>
              </motion.div>
            </AnimatePresence>
            {showNavigationButtons && (
              <>
                <button
                  onClick={prevTestimonial}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors hidden sm:block"
                  aria-label="Previous testimonial"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={nextTestimonial}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors hidden sm:block"
                  aria-label="Next testimonial"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </>
            )}
          </div>
          {showPaginationDots && (
            <div className="flex justify-center mt-6 space-x-2">
              {testimonials.map((testimonial, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-12 h-12 rounded-full overflow-hidden transition-all duration-300 ${index === currentIndex ? 'ring-2 ring-blue-500 ring-offset-2' : 'opacity-50 hover:opacity-100'}`}
                  aria-label={`Go to testimonial ${index + 1}`}
                >
                  <Image
                    src={testimonial.image}
                    alt={testimonial.name}
                    width={48}
                    height={48}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestimonialSlider;
