"use client";
import React from "react";
import { ArrowRight, Code, Users } from "lucide-react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Image from "next/image";

const CTASection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className="py-20 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-8">
          <motion.p variants={itemVariants} className="uppercase font-bold text-sm mb-4 text-blue-600 tracking-wide">
            More than 500+ Satisfied Customers
          </motion.p>
          <motion.h2
            variants={itemVariants}
            className="text-3xl font-bold text-gray-900 sm:text-5xl tracking-tighter"
          >
           Supercharge Your Business Growth with Tailored Digital Solutions!
          </motion.h2>
          <motion.p variants={itemVariants} className="mt-4">
          Empower your brand with cutting-edge technology and innovative strategies. From website development to digital marketing, we offer solutions that drive growth, enhance customer engagement, and maximize your success. Let’s build your digital future together.
          </motion.p>
          <motion.div variants={itemVariants} className="mt-10 flex">
            <a
              href="/contact/"
              className="inline-flex px-8 py-3 border border-transparent font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out"
            >
              Request a Quote
             
            </a>
          </motion.div>
          <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2">
            {[
              {
                title: "Custom Solutions",
                icon: Code,
                description:
                  "Tailored software and digital platforms that fit your business needs perfectly.",
              },
              {
                title: "Dedicated Experts",
                icon: Users,
                description:
                  "Work with a team of skilled professionals committed to driving your success.",
              },
            ].map((item, index) => (
              <motion.div
                key={item.title}
                variants={itemVariants}
                className="border-t border-gray-200 pt-4"
              >
                <div className="flex items-center mb-2">
                  <item.icon className="h-6 w-6 text-blue-600 mr-2" />
                  <h3 className="text-lg font-bold text-gray-900">
                    {item.title}
                  </h3>
                </div>
                <p className="text-sm">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
        <motion.div
          variants={itemVariants}
          className="lg:w-1/2 mt-10 lg:mt-0"
        >
          <Image
            width={500}
            height={500}
            layout="responsive"
            objectFit="contain"
            quality={100}
            src="/images/cta-image.png"
            alt="Infyways Call to Action"
            className="w-full h-auto "
          />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default CTASection;